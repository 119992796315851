import { useCallback, useMemo } from "react"
import ReactServer from "react-dom/server"

import * as Highcharts from "highcharts"
import HighchartsMore from "highcharts/highcharts-more"
import HighchartsBoost from "highcharts/modules/boost"
import HighchartsExportData from "highcharts/modules/export-data"
import HighchartsExport from "highcharts/modules/exporting"
import HighchartsReact from "highcharts-react-official"

import { mAxisBottom } from "src/components/Paradise/ParadiseCharts/sharedChartConfig"
import { IDeviceReadings } from "src/data/devices/types/deviceTypes"
import {
  Box,
  Content,
  DownArrow,
  Header,
  Row,
  UpArrow,
  Value,
} from "src/ui/Graphs/LineGraph/DataPointTooltip"
import { formatDate } from "src/utils/l10n"

HighchartsExport(Highcharts)
HighchartsExportData(Highcharts)
HighchartsMore(Highcharts)
HighchartsBoost(Highcharts)
export function ParadiseLineChart({
  data,
  thresholdValue,
  thresholdLabel,
  tooltip,
  onZoom,
  onZoomReset,
  smooth = true,
  canExport = false,
  zoom = "x",
}: {
  data: IDeviceReadings["values"]
  tooltip: {
    unit: string
    formatter?: (context: {
      date: Date
      value: number
      min?: number
      max?: number
    }) => React.ReactElement
  }
  canExport?: boolean
  thresholdValue?: number
  thresholdLabel?: string
  onZoom?: (startDate: Date, endDate: Date) => void
  onZoomReset?: () => void
  smooth?: boolean
  zoom?: "x" | "xy" | "y"
}) {
  const minMaxIncluded = !!data[0].max && !!data[0].min

  const series = useMemo<Highcharts.Options["series"]>(() => {
    const dataSeries: Highcharts.Options["series"] = [
      {
        name: "Value",
        type: smooth ? "spline" : "line",
        color: "black",
        showInLegend: false,
        animation: true,
        connectNulls: true,
        marker: {
          enabled: false,
          fillColor: "rgba(92, 119, 235, 1.000)",
          lineWidth: 1,
          lineColor: "white",
          radius: 2,
        },
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        data: data.map((dataPoint) => [
          new Date(dataPoint.datetime).getTime(),
          dataPoint.value,
        ]),
      },
    ]

    if (minMaxIncluded) {
      dataSeries.push({
        name: "Area",
        type: smooth ? "areasplinerange" : "arearange",
        linkedTo: ":previous",
        lineColor: "transparent",
        fillColor: "rgba(0,0,0,0.7)",
        opacity: 0.3,
        connectNulls: false,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
        },
        zIndex: 1,
        data: data.map((dataPoint) => [
          new Date(dataPoint.datetime).getTime(),
          dataPoint.min,
          dataPoint.max,
        ]),
      })
    }

    return dataSeries
  }, [data, minMaxIncluded, smooth])

  const tooltipFormatter = useCallback(
    (context: Highcharts.TooltipFormatterContextObject) => {
      if (!context.x || !context.y) return false

      if (tooltip.formatter) {
        return ReactServer.renderToString(
          tooltip.formatter({
            date: new Date(context.x),
            value: context.y,
            min: context.points?.[1]?.point.low,
            max: context.points?.[1]?.point.high,
          })
        )
      }

      const decimals = 2
      const high = context.points?.[1].point?.high?.toFixed(decimals)
      const low = context.points?.[1].point?.low?.toFixed(decimals)

      if (minMaxIncluded) {
        return ReactServer.renderToString(
          <Box>
            <Header>
              {formatDate({
                date: new Date(context.x).toISOString(),
                timezone: "utc",
                clockType: "24",
              })}
            </Header>
            <Content>
              <Row>
                Average: <Value>{context.y.toFixed(2)}</Value> C
              </Row>
              <Row>
                <DownArrow>↓</DownArrow>
                <Value>{low ? low + tooltip.unit : "-"}</Value>
                <UpArrow>↑</UpArrow>
                <Value>{high ? high + tooltip.unit : "-"}</Value>
              </Row>
            </Content>
          </Box>
        )
      }

      return ReactServer.renderToString(
        <Box>
          <Header>
            {formatDate({
              date: new Date(context.x).toISOString(),
              timezone: "utc",
              clockType: "24",
            })}
          </Header>
          <Content>
            <Row>
              Value: <Value>{context.y.toFixed(2)}</Value> {tooltip.unit}
            </Row>
          </Content>
        </Box>
      )
    },
    [minMaxIncluded, tooltip]
  )
  const options = useMemo<Highcharts.Options>(
    () => ({
      chart: {
        height: 500,
        zooming: {
          type: zoom,
          pinchType: zoom,
          resetButton: {
            position: {
              // Make sure the button doesn't overlap the hamburger menu button
              x: -40,
            },
          },
        },
        events: {
          selection(e) {
            if (onZoom) {
              if (e.resetSelection) {
                onZoomReset?.()
                this.xAxis[0].setExtremes(
                  this.xAxis[0].getExtremes().dataMin,
                  this.xAxis[0].getExtremes().dataMax,
                  false
                )
              } else {
                onZoom(new Date(e.xAxis[0].min), new Date(e.xAxis[0].max))
                this.showResetZoom()
                return false
              }
            }

            return true
          },
        },
      },
      title: {
        text: "",
      },
      xAxis: {
        type: "datetime",
        lineWidth: mAxisBottom.strokeWidth,
        lineColor: mAxisBottom.stroke,
        tickColor: mAxisBottom.tickStroke,
        crosshair: {
          color: "rgba(92, 119, 235, 1.000)",
          dashStyle: "ShortDot",
          width: 2,
        },
      },
      exporting: {
        enabled: canExport,
        csv: {},
      },
      credits: {
        enabled: false,
      },
      yAxis: {
        type: "linear",
        title: {
          text: "",
        },
        endOnTick: true,
        startOnTick: true,
        crosshair: false,
        plotLines: thresholdValue
          ? [
              {
                value: thresholdValue,
                color: "red",
                dashStyle: "ShortDash",
                width: 2,
                zIndex: 10,
                label: {
                  text: thresholdLabel,
                  align: "right",
                  style: {
                    color: "red",
                    backgroundColor: "red",
                  },
                },
              },
            ]
          : [],
      },
      series,
      tooltip: {
        useHTML: true,
        padding: 0,
        shared: true,
        shadow: false,
        positioner(labelWidth, labelHeight, point) {
          let x = point.plotX + this.chart.plotLeft + 10
          let y = point.plotY + this.chart.plotTop + 10

          if (x + labelWidth >= this.chart.plotWidth) {
            x = point.plotX - labelWidth + this.chart.plotLeft - 10
          }

          if (y + labelHeight >= this.chart.plotHeight) {
            y = point.plotY - labelHeight + this.chart.plotTop - 10
          }

          return {
            x: x,
            y: y,
          }
        },
        formatter() {
          return tooltipFormatter(this)
        },
      },
    }),
    [
      series,
      thresholdLabel,
      thresholdValue,
      tooltipFormatter,
      onZoom,
      onZoomReset,
      canExport,
      zoom,
    ]
  )

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
